<template>
  <FormulateForm
    ref="eRegisterForm"
    name="eRegisterForm"
  >
    <b-row>
      <b-col md="2">
        <FormulateInput
          v-if="isReadOnly || isEdit"
          id="register-person-type"
          v-model="personForm.personType"
          type="label"
          :options="[
            { value: 'Individual', label: 'Física' },
            { value: 'Juridical', label: 'Jurídica' },
          ]"
          :label="$t('Tipo')"
        />
        <FormulateInput
          v-else
          id="register-person-type"
          v-model="personForm.personType"
          type="radio"
          :options="{ Individual: 'Física', Juridical: 'Jurídica' }"
          :label="$t('Tipo')"
          :element-class="['d-flex', 'mt-1']"
          @input="onChangePersonType"
        />
      </b-col>

      <template v-if="isIndividual">
        <b-col md="2">
          <FormulateInput
            v-if="isReadOnly || isEdit"
            id="register-document"
            v-model="personForm.document"
            type="label"
            filter="cpfCnpj"
            :label="$t('CPF')"
          />
          <FormulateInput
            v-else
            id="register-document"
            ref="inputIndividualDocument"
            v-model="personForm.document"
            type="text-mask"
            mask="###.###.###-##"
            :placeholder="$t('999.999.999-99')"
            :label="$t('CPF')"
            class="required"
            validation="^required|cpfCnpj"
          />
        </b-col>
        <b-col md="5">
          <FormulateInput
            v-if="isReadOnly"
            id="register-individual-name"
            v-model="personForm.name"
            type="label"
            :label="$t('Nome')"
          />
          <FormulateInput
            v-else
            id="register-individual-name"
            ref="inputIndividualName"
            v-model="personForm.name"
            :label="$t('Nome')"
            class="required"
            validation="required"
          />
        </b-col>

        <b-col md="3">
          <FormulateInput
            v-if="isReadOnly"
            id="register-rg"
            v-model="personForm.rg.document"
            type="label"
            :label="$t('RG')"
          />
          <FormulateInput
            v-else
            id="register-rg"
            v-model="personForm.rg.document"
            type="text"
            :label="$t('RG')"
          />
        </b-col>
        <!-- <b-col md="2">
          <FormulateInput
            id="register-issuer"
            v-model="personForm.rg.issuer"
            type="text"
            :label="$t('Emissor')"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            id="register-uf"
            v-model="personForm.rg.issuerState"
            type="vue-select"
            :label="$t('UF')"
            :placeholder="$t('Selecione')"
            :options="getComboStates"
          />
        </b-col> -->
        <b-col md="2">
          <FormulateInput
            v-if="isReadOnly"
            id="register-gender"
            v-model="personForm.gender"
            type="label"
            :label="$t('Sexo')"
            :options="genderTypes()"
          />
          <FormulateInput
            v-else
            id="register-gender"
            v-model="personForm.gender"
            type="vue-select"
            :label="$t('Sexo')"
            :placeholder="$t('Selecione')"
            :options="genderTypes()"
          />
        </b-col>
        <b-col md="3">
          <FormulateInput
            v-if="isReadOnly"
            id="register-birthdate"
            v-model="personForm.birthdate"
            type="label"
            filter="date"
            :label="$t('Aniversário')"
          />
          <FormulateInput
            v-else
            id="register-birthdate"
            v-model="personForm.birthdate"
            type="datepicker"
            :label="$t('Aniversário')"
            :placeholder="$t('dd/mm/aaaa')"
            :max="today"
            no-flip
          />
        </b-col>
      </template>
      <!-- TODO ver labels de jurídico -->
      <template v-else-if="isJuridical">
        <b-col md="2">
          <FormulateInput
            v-if="isReadOnly || isEdit"
            id="register-document"
            v-model="personForm.document"
            type="label"
            filter="cpfCnpj"
            :label="$t('CNPJ')"
          />
          <FormulateInput
            v-else
            id="register-document"
            ref="inputJuridicalDocument"
            v-model="personForm.document"
            type="text-mask"
            mask="##.###.###/####-##"
            :label="$t('CNPJ')"
            class="required"
            validation="required|cpfCnpj"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            v-if="isReadOnly"
            id="register-company-name"
            v-model="personForm.companyName"
            type="label"
            :label="$t('Razão Social')"
          />
          <FormulateInput
            v-else
            id="register-company-name"
            ref="inputJuridicalCompanyName"
            v-model="personForm.companyName"
            type="text"
            :label="$t('Razão Social')"
            class="required"
            validation="required"
          />
        </b-col>
        <b-col md="4">
          <FormulateInput
            v-if="isReadOnly"
            id="register-trading-name"
            v-model="personForm.name"
            type="label"
            :label="$t('Nome Fantasia')"
          />
          <FormulateInput
            v-else
            id="register-trading-name"
            v-model="personForm.name"
            type="text"
            :label="$t('Nome Fantasia')"
            class="required"
            validation="required"
          />
        </b-col>

        <b-col md="3">
          <FormulateInput
            v-if="isReadOnly"
            id="register-state-registration-type"
            v-model="personForm.stateRegistrationType"
            type="label"
            :options="indicatorIETypes()"
            :label="$t('Indicador da IE')"
            class="required"
            validation="required"
          />
          <FormulateInput
            v-else
            id="register-state-registration-type"
            v-model="personForm.stateRegistrationType"
            type="vue-select"
            class="required"
            validation="required"
            :options="indicatorIETypes()"
            :placeholder="$t('Selecione')"
            :label="$t('Indicador da IE')"
            @input="onSelectStateRegistration"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            v-if="isReadOnly"
            id="register-state-registration"
            v-model="personForm.stateRegistration"
            type="label"
            :label="$t('Inscrição Estadual')"
          />
          <FormulateInput
            v-else
            id="register-state-registration"
            v-model="personForm.stateRegistration"
            type="text"
            :validation="isRequiredIE ? 'required' : ''"
            :class="{
              required: isRequiredIE,
            }"
            :label="$t('Inscrição Estadual')"
            maxlength="20"
            :disabled="!isRequiredIE"
          />
        </b-col>
        <b-col md="2">
          <FormulateInput
            v-if="isReadOnly"
            id="register-municipal-inscription"
            v-model="personForm.municipalInscription"
            type="label"
            :label="$t('Inscrição Municipal')"
          />
          <FormulateInput
            v-else
            id="register-municipal-inscription"
            v-model="personForm.municipalInscription"
            type="text"
            :label="$t('Inscrição Municipal')"
            maxlength="20"
          />
        </b-col>
      </template>

      <b-col>
        <FormulateInput
          v-if="isReadOnly"
          id="register-email"
          v-model="personForm.email"
          type="label"
          :label="$t('E-mail')"
        />
        <FormulateInput
          v-else
          id="register-email"
          v-model="personForm.email"
          type="text"
          :label="$t('E-mail')"
          :placeholder="$t('nome@email.com')"
          validation="optional|email"
        />
      </b-col>

      <b-col>
        <FormulateInput
          v-if="isReadOnly"
          id="register-email_invoice"
          v-model="personForm.emailInvoice"
          type="label"
          :label="$t('E-mail p/ NF-e')"
        />
        <FormulateInput
          v-else
          id="register-email_invoice"
          v-model="personForm.emailInvoice"
          type="text"
          :label="$t('E-mail p/ NF-e')"
          :placeholder="$t('nome@email.com')"
          validation="optional|email"
          :instructions="[
            { text: $t('E-mail do destinatário da NF-e') },
            {
              text: $t(
                'Caso este campo não seja preenchido. Será pego o email do campo obrigatório E-mail'
              ),
            },
          ]"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col v-if="isSupplier">
        <FormulateInput
          v-if="isReadOnly"
          id="register-site"
          v-model="personForm.siteUrl"
          name="siteUrl"
          type="label"
          :label="$t('Site')"
        />
        <FormulateInput
          v-else
          id="register-site"
          v-model="personForm.siteUrl"
          name="siteUrl"
          type="text"
          :label="$t('Site')"
          :placeholder="$t('Ex: http://meusite.com.br')"
          validation="url"
        />
      </b-col>
    </b-row>

    <b-row>
      <b-col md="6">
        <b-row
          v-if="isReadOnly"
          class="py-1 border-right"
        >
          <b-col md="12">
            <p class="h3">
              {{ $t('Telefones') }}
            </p>
          </b-col>
          <template v-for="(tel, index) in personForm.telephones">
            <b-col
              :key="`phone_type_${tel.id}`"
              md="2"
            >
              <FormulateInput
                :id="`register-phone-type-${index}`"
                v-model="tel.type"
                type="label"
                :aria-label="$t('Tipo do número de contato')"
                :label="$t('Tipo')"
                :options="telephoneTypes()"
              />
            </b-col>
            <b-col
              :key="`phone_number_${tel.id}`"
              md="4"
            >
              <FormulateInput
                :id="`register-phone-number-${index}`"
                v-model="tel.number"
                type="label"
                filter="phone"
                :label="$t('Número')"
              />
            </b-col>
          </template>
        </b-row>
        <FormulateInput
          v-else
          v-model="personForm.telephones"
          type="group"
          label="Telefones"
          :repeatable="true"
          :add-label="$t('Adicionar Telefone')"
        >
          <template #default="{ index }">
            <b-row>
              <b-col md="4">
                <FormulateInput
                  :id="`register-phone-type-${index}`"
                  name="type"
                  type="vue-select"
                  :aria-label="$t('Tipo do número de contato')"
                  :label="$t('Tipo')"
                  :options="telephoneTypes()"
                  :clearable="false"
                  :class="{ required: isCustomer }"
                  :validation="isCustomer ? 'required' : ''"
                  :disabled="isCustomer && index === 0"
                />
              </b-col>
              <b-col md="8">
                <FormulateInput
                  :id="`register-phone-number-${index}`"
                  name="number"
                  type="text-mask"
                  maxlength="15"
                  :instruction="$t('Exemplo: (99) 99999-9999')"
                  :mask="personForm.telephones[index] &&
                    personForm.telephones[index].type === telephoneTypeEnum.MOBILE
                    ? ['(##) #####-####']
                    : ['(##) ####-####']
                  "
                  :label="$t('Número')"
                  :class="{ required: isCustomer }"
                  :validation="isCustomer ? 'required' : ''"
                />
              </b-col>
            </b-row>
          </template>

          <template #remove="{ removeItem, index }">
            <div
              v-if="index > 0"
              style="position: absolute; right: 10px; top: 40px"
            >
              <b-button
                style="padding: 2px; width: 25px"
                @click="removeItem"
              >
                <div>
                  <b-icon icon="x" />
                </div>
              </b-button>
            </div>
          </template>
        </FormulateInput>
      </b-col>

      <b-col>
        <slot name="others-inputs" />
      </b-col>
    </b-row>
  </FormulateForm>
</template>

<script>
import { BRow, BCol, BButton, BIcon } from 'bootstrap-vue'
import { genderTypes, indicatorIETypes, personTypes, province, telephoneTypes } from '@/mixins'
import { mapActions, mapGetters } from 'vuex'
import { email as email_rule } from 'vee-validate/dist/rules'

export default {
  name: 'ERegisterForm',

  components: { BCol, BButton, BIcon, BRow },

  mixins: [indicatorIETypes, genderTypes, province, telephoneTypes, personTypes],

  props: {
    personForm: {
      type: Object,
      required: true,
    },
    isEdit: {
      type: Boolean,
      default: false,
    },
    isReadOnly: {
      type: Boolean,
      default: false,
    },
    isCustomer: {
      type: Boolean,
      default: false,
    },
    isSupplier: {
      type: Boolean,
      default: false,
    },
  },

  data() {
    return {
      personTypeFlag: false,
    }
  },

  computed: {
    ...mapGetters('common/states', ['getComboStates']),

    isJuridical() {
      return this.personForm?.personType === 'Juridical'
    },

    isRequiredIE() {
      return ![
        this.indicatorIETypesEnum.EXEMPT_REGISTRATION,
        this.indicatorIETypesEnum.NO_TAXPAYER,
      ].includes(this.personForm.stateRegistrationType)
    },

    isIndividual() {
      return this.personForm?.personType === 'Individual'
    },

    today() {
      return new Date()
    },
  },

  watch: {
    // eslint-disable-next-line func-names
    'personForm.id': function () {
      setTimeout(() => {
        this.personTypeFlag = false
      }, 200)
    },
  },

  mounted() {
    this.fetchStates()
    this.personTypeFlag = this.isEdit
  },

  methods: {
    ...mapActions('common/states', ['fetchStates']),

    async onChangePersonType(val) {
      this.resetValidationMessages()
      if (this.personTypeFlag || !this.hasPersonTypeData()) {
        this.personTypeFlag = false
        return
      }

      this.personTypeFlag = true
      const confirm = await this.confirmHtml({
        title: this.$t('Confirmar alteração?'),
        html: this.$t(
          `Os dados atuais (CPF, RG, Nome, Aniversário, Gênero ou CNPJ, IE, IM, Razão Social, Nome Fantasia)
          <b>serão apagados do formulário.</b>`
        ),
      })
      if (confirm) {
        this.$emit('clean-person-data')
        this.personTypeFlag = false
      } else {
        this.personForm.personType = val === 'Individual' ? 'Juridical' : 'Individual'
        this.resetValidationMessages()
      }
    },

    onSelectStateRegistration(val) {
      if (val === this.indicatorIETypesEnum.EXEMPT_REGISTRATION) {
        this.personForm.stateRegistration = ''
      }
    },

    hasPersonTypeData() {
      const {
        document,
        name,
        rg,
        gender,
        birthdate,
        companyName,
        stateRegistrationType,
        stateRegistration,
        municipalInscription,
      } = this.personForm

      return (
        document ||
        name ||
        rg?.document ||
        rg?.issuer ||
        rg?.issuerState ||
        gender ||
        birthdate ||
        companyName ||
        stateRegistrationType ||
        stateRegistration ||
        municipalInscription
      )
    },

    validate() {
      const { name, companyName, document, telephones, email, emailInvoice, stateRegistration } =
        this.personForm
      const inputErrors = {}

      // as atribuições são apenas para poder adicionar o atributo, não a mensagem de erro
      // para mensagem de erro deve usar o atributo validation no FormulateInput
      if (!document) inputErrors.document = ''
      if (!name) inputErrors.name = ''
      if (email && !email_rule.validate(email)) inputErrors.email = ''

      if (this.isJuridical) {
        if (!companyName) inputErrors.companyName = ''
        if (emailInvoice && !email_rule.validate(emailInvoice)) inputErrors.emailInvoice = ''
        if (this.isRequiredIE && (!stateRegistration || !stateRegistration.trim())) {
          inputErrors.stateRegistration = ''
        }
      }

      this.$refs.eRegisterForm.showErrors()
      if (this.isCustomer) {
        if (Array.isArray(telephones) && telephones.some(tel => !tel.type || !tel.number)) {
          return false
        }
      }

      // não é utilizado o this.$refs.eRegisterForm.isValid pois ele valida os inputs ocultos de (fisica ou juridica)
      if (Object.keys(inputErrors).length > 0) {
        this.$formulate.handle({ inputErrors }, 'eRegisterForm')
        return false
      }

      return true
    },

    // necessário para resetar mensagem de erro nos inputs ao alterar o "personType"
    resetValidationMessages() {
      if (this.personForm.personType === this.personTypeEnum.JURIDICAL) {
        const inpIndDoc = this.$refs.inputIndividualDocument
        const inpIndName = this.$refs.inputIndividualName

        if (!(inpIndDoc || inpIndName)) return

        inpIndDoc.validationErrors = [this.$t('CNPJ é obrigatório')]
        inpIndName.validationErrors = [this.$t('Razão Social é obrigatório')]
      } else if (this.personForm.personType === this.personTypeEnum.INDIVIDUAL) {
        const inpJurDoc = this.$refs.inputJuridicalDocument
        const inpJurCompName = this.$refs.inputJuridicalCompanyName

        if (!(inpJurDoc || inpJurCompName)) return

        inpJurDoc.validationErrors = [this.$t('CPF é obrigatório')]
        inpJurCompName.validationErrors = [this.$t('Nome é obrigatório')]
      }
    },
  },
}
</script>

<style></style>
