var render = function () {
  var _vm = this
  var _h = _vm.$createElement
  var _c = _vm._self._c || _h
  return _c(
    "FormulateForm",
    { ref: "eRegisterForm", attrs: { name: "eRegisterForm" } },
    [
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "2" } },
            [
              _vm.isReadOnly || _vm.isEdit
                ? _c("FormulateInput", {
                    attrs: {
                      id: "register-person-type",
                      type: "label",
                      options: [
                        { value: "Individual", label: "Física" },
                        { value: "Juridical", label: "Jurídica" },
                      ],
                      label: _vm.$t("Tipo"),
                    },
                    model: {
                      value: _vm.personForm.personType,
                      callback: function ($$v) {
                        _vm.$set(_vm.personForm, "personType", $$v)
                      },
                      expression: "personForm.personType",
                    },
                  })
                : _c("FormulateInput", {
                    attrs: {
                      id: "register-person-type",
                      type: "radio",
                      options: { Individual: "Física", Juridical: "Jurídica" },
                      label: _vm.$t("Tipo"),
                      "element-class": ["d-flex", "mt-1"],
                    },
                    on: { input: _vm.onChangePersonType },
                    model: {
                      value: _vm.personForm.personType,
                      callback: function ($$v) {
                        _vm.$set(_vm.personForm, "personType", $$v)
                      },
                      expression: "personForm.personType",
                    },
                  }),
            ],
            1
          ),
          _vm.isIndividual
            ? [
                _c(
                  "b-col",
                  { attrs: { md: "2" } },
                  [
                    _vm.isReadOnly || _vm.isEdit
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "register-document",
                            type: "label",
                            filter: "cpfCnpj",
                            label: _vm.$t("CPF"),
                          },
                          model: {
                            value: _vm.personForm.document,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "document", $$v)
                            },
                            expression: "personForm.document",
                          },
                        })
                      : _c("FormulateInput", {
                          ref: "inputIndividualDocument",
                          staticClass: "required",
                          attrs: {
                            id: "register-document",
                            type: "text-mask",
                            mask: "###.###.###-##",
                            placeholder: _vm.$t("999.999.999-99"),
                            label: _vm.$t("CPF"),
                            validation: "^required|cpfCnpj",
                          },
                          model: {
                            value: _vm.personForm.document,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "document", $$v)
                            },
                            expression: "personForm.document",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "5" } },
                  [
                    _vm.isReadOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "register-individual-name",
                            type: "label",
                            label: _vm.$t("Nome"),
                          },
                          model: {
                            value: _vm.personForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "name", $$v)
                            },
                            expression: "personForm.name",
                          },
                        })
                      : _c("FormulateInput", {
                          ref: "inputIndividualName",
                          staticClass: "required",
                          attrs: {
                            id: "register-individual-name",
                            label: _vm.$t("Nome"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.personForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "name", $$v)
                            },
                            expression: "personForm.name",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "3" } },
                  [
                    _vm.isReadOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "register-rg",
                            type: "label",
                            label: _vm.$t("RG"),
                          },
                          model: {
                            value: _vm.personForm.rg.document,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm.rg, "document", $$v)
                            },
                            expression: "personForm.rg.document",
                          },
                        })
                      : _c("FormulateInput", {
                          attrs: {
                            id: "register-rg",
                            type: "text",
                            label: _vm.$t("RG"),
                          },
                          model: {
                            value: _vm.personForm.rg.document,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm.rg, "document", $$v)
                            },
                            expression: "personForm.rg.document",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "2" } },
                  [
                    _vm.isReadOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "register-gender",
                            type: "label",
                            label: _vm.$t("Sexo"),
                            options: _vm.genderTypes(),
                          },
                          model: {
                            value: _vm.personForm.gender,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "gender", $$v)
                            },
                            expression: "personForm.gender",
                          },
                        })
                      : _c("FormulateInput", {
                          attrs: {
                            id: "register-gender",
                            type: "vue-select",
                            label: _vm.$t("Sexo"),
                            placeholder: _vm.$t("Selecione"),
                            options: _vm.genderTypes(),
                          },
                          model: {
                            value: _vm.personForm.gender,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "gender", $$v)
                            },
                            expression: "personForm.gender",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "3" } },
                  [
                    _vm.isReadOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "register-birthdate",
                            type: "label",
                            filter: "date",
                            label: _vm.$t("Aniversário"),
                          },
                          model: {
                            value: _vm.personForm.birthdate,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "birthdate", $$v)
                            },
                            expression: "personForm.birthdate",
                          },
                        })
                      : _c("FormulateInput", {
                          attrs: {
                            id: "register-birthdate",
                            type: "datepicker",
                            label: _vm.$t("Aniversário"),
                            placeholder: _vm.$t("dd/mm/aaaa"),
                            max: _vm.today,
                            "no-flip": "",
                          },
                          model: {
                            value: _vm.personForm.birthdate,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "birthdate", $$v)
                            },
                            expression: "personForm.birthdate",
                          },
                        }),
                  ],
                  1
                ),
              ]
            : _vm.isJuridical
            ? [
                _c(
                  "b-col",
                  { attrs: { md: "2" } },
                  [
                    _vm.isReadOnly || _vm.isEdit
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "register-document",
                            type: "label",
                            filter: "cpfCnpj",
                            label: _vm.$t("CNPJ"),
                          },
                          model: {
                            value: _vm.personForm.document,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "document", $$v)
                            },
                            expression: "personForm.document",
                          },
                        })
                      : _c("FormulateInput", {
                          ref: "inputJuridicalDocument",
                          staticClass: "required",
                          attrs: {
                            id: "register-document",
                            type: "text-mask",
                            mask: "##.###.###/####-##",
                            label: _vm.$t("CNPJ"),
                            validation: "required|cpfCnpj",
                          },
                          model: {
                            value: _vm.personForm.document,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "document", $$v)
                            },
                            expression: "personForm.document",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "4" } },
                  [
                    _vm.isReadOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "register-company-name",
                            type: "label",
                            label: _vm.$t("Razão Social"),
                          },
                          model: {
                            value: _vm.personForm.companyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "companyName", $$v)
                            },
                            expression: "personForm.companyName",
                          },
                        })
                      : _c("FormulateInput", {
                          ref: "inputJuridicalCompanyName",
                          staticClass: "required",
                          attrs: {
                            id: "register-company-name",
                            type: "text",
                            label: _vm.$t("Razão Social"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.personForm.companyName,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "companyName", $$v)
                            },
                            expression: "personForm.companyName",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "4" } },
                  [
                    _vm.isReadOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "register-trading-name",
                            type: "label",
                            label: _vm.$t("Nome Fantasia"),
                          },
                          model: {
                            value: _vm.personForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "name", $$v)
                            },
                            expression: "personForm.name",
                          },
                        })
                      : _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "register-trading-name",
                            type: "text",
                            label: _vm.$t("Nome Fantasia"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.personForm.name,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "name", $$v)
                            },
                            expression: "personForm.name",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "3" } },
                  [
                    _vm.isReadOnly
                      ? _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "register-state-registration-type",
                            type: "label",
                            options: _vm.indicatorIETypes(),
                            label: _vm.$t("Indicador da IE"),
                            validation: "required",
                          },
                          model: {
                            value: _vm.personForm.stateRegistrationType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.personForm,
                                "stateRegistrationType",
                                $$v
                              )
                            },
                            expression: "personForm.stateRegistrationType",
                          },
                        })
                      : _c("FormulateInput", {
                          staticClass: "required",
                          attrs: {
                            id: "register-state-registration-type",
                            type: "vue-select",
                            validation: "required",
                            options: _vm.indicatorIETypes(),
                            placeholder: _vm.$t("Selecione"),
                            label: _vm.$t("Indicador da IE"),
                          },
                          on: { input: _vm.onSelectStateRegistration },
                          model: {
                            value: _vm.personForm.stateRegistrationType,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.personForm,
                                "stateRegistrationType",
                                $$v
                              )
                            },
                            expression: "personForm.stateRegistrationType",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "2" } },
                  [
                    _vm.isReadOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "register-state-registration",
                            type: "label",
                            label: _vm.$t("Inscrição Estadual"),
                          },
                          model: {
                            value: _vm.personForm.stateRegistration,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "stateRegistration", $$v)
                            },
                            expression: "personForm.stateRegistration",
                          },
                        })
                      : _c("FormulateInput", {
                          class: {
                            required: _vm.isRequiredIE,
                          },
                          attrs: {
                            id: "register-state-registration",
                            type: "text",
                            validation: _vm.isRequiredIE ? "required" : "",
                            label: _vm.$t("Inscrição Estadual"),
                            maxlength: "20",
                            disabled: !_vm.isRequiredIE,
                          },
                          model: {
                            value: _vm.personForm.stateRegistration,
                            callback: function ($$v) {
                              _vm.$set(_vm.personForm, "stateRegistration", $$v)
                            },
                            expression: "personForm.stateRegistration",
                          },
                        }),
                  ],
                  1
                ),
                _c(
                  "b-col",
                  { attrs: { md: "2" } },
                  [
                    _vm.isReadOnly
                      ? _c("FormulateInput", {
                          attrs: {
                            id: "register-municipal-inscription",
                            type: "label",
                            label: _vm.$t("Inscrição Municipal"),
                          },
                          model: {
                            value: _vm.personForm.municipalInscription,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.personForm,
                                "municipalInscription",
                                $$v
                              )
                            },
                            expression: "personForm.municipalInscription",
                          },
                        })
                      : _c("FormulateInput", {
                          attrs: {
                            id: "register-municipal-inscription",
                            type: "text",
                            label: _vm.$t("Inscrição Municipal"),
                            maxlength: "20",
                          },
                          model: {
                            value: _vm.personForm.municipalInscription,
                            callback: function ($$v) {
                              _vm.$set(
                                _vm.personForm,
                                "municipalInscription",
                                $$v
                              )
                            },
                            expression: "personForm.municipalInscription",
                          },
                        }),
                  ],
                  1
                ),
              ]
            : _vm._e(),
          _c(
            "b-col",
            [
              _vm.isReadOnly
                ? _c("FormulateInput", {
                    attrs: {
                      id: "register-email",
                      type: "label",
                      label: _vm.$t("E-mail"),
                    },
                    model: {
                      value: _vm.personForm.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.personForm, "email", $$v)
                      },
                      expression: "personForm.email",
                    },
                  })
                : _c("FormulateInput", {
                    attrs: {
                      id: "register-email",
                      type: "text",
                      label: _vm.$t("E-mail"),
                      placeholder: _vm.$t("nome@email.com"),
                      validation: "optional|email",
                    },
                    model: {
                      value: _vm.personForm.email,
                      callback: function ($$v) {
                        _vm.$set(_vm.personForm, "email", $$v)
                      },
                      expression: "personForm.email",
                    },
                  }),
            ],
            1
          ),
          _c(
            "b-col",
            [
              _vm.isReadOnly
                ? _c("FormulateInput", {
                    attrs: {
                      id: "register-email_invoice",
                      type: "label",
                      label: _vm.$t("E-mail p/ NF-e"),
                    },
                    model: {
                      value: _vm.personForm.emailInvoice,
                      callback: function ($$v) {
                        _vm.$set(_vm.personForm, "emailInvoice", $$v)
                      },
                      expression: "personForm.emailInvoice",
                    },
                  })
                : _c("FormulateInput", {
                    attrs: {
                      id: "register-email_invoice",
                      type: "text",
                      label: _vm.$t("E-mail p/ NF-e"),
                      placeholder: _vm.$t("nome@email.com"),
                      validation: "optional|email",
                      instructions: [
                        { text: _vm.$t("E-mail do destinatário da NF-e") },
                        {
                          text: _vm.$t(
                            "Caso este campo não seja preenchido. Será pego o email do campo obrigatório E-mail"
                          ),
                        },
                      ],
                    },
                    model: {
                      value: _vm.personForm.emailInvoice,
                      callback: function ($$v) {
                        _vm.$set(_vm.personForm, "emailInvoice", $$v)
                      },
                      expression: "personForm.emailInvoice",
                    },
                  }),
            ],
            1
          ),
        ],
        2
      ),
      _c(
        "b-row",
        [
          _vm.isSupplier
            ? _c(
                "b-col",
                [
                  _vm.isReadOnly
                    ? _c("FormulateInput", {
                        attrs: {
                          id: "register-site",
                          name: "siteUrl",
                          type: "label",
                          label: _vm.$t("Site"),
                        },
                        model: {
                          value: _vm.personForm.siteUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.personForm, "siteUrl", $$v)
                          },
                          expression: "personForm.siteUrl",
                        },
                      })
                    : _c("FormulateInput", {
                        attrs: {
                          id: "register-site",
                          name: "siteUrl",
                          type: "text",
                          label: _vm.$t("Site"),
                          placeholder: _vm.$t("Ex: http://meusite.com.br"),
                          validation: "url",
                        },
                        model: {
                          value: _vm.personForm.siteUrl,
                          callback: function ($$v) {
                            _vm.$set(_vm.personForm, "siteUrl", $$v)
                          },
                          expression: "personForm.siteUrl",
                        },
                      }),
                ],
                1
              )
            : _vm._e(),
        ],
        1
      ),
      _c(
        "b-row",
        [
          _c(
            "b-col",
            { attrs: { md: "6" } },
            [
              _vm.isReadOnly
                ? _c(
                    "b-row",
                    { staticClass: "py-1 border-right" },
                    [
                      _c("b-col", { attrs: { md: "12" } }, [
                        _c("p", { staticClass: "h3" }, [
                          _vm._v(" " + _vm._s(_vm.$t("Telefones")) + " "),
                        ]),
                      ]),
                      _vm._l(_vm.personForm.telephones, function (tel, index) {
                        return [
                          _c(
                            "b-col",
                            { key: "phone_type_" + tel.id, attrs: { md: "2" } },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "register-phone-type-" + index,
                                  type: "label",
                                  "aria-label": _vm.$t(
                                    "Tipo do número de contato"
                                  ),
                                  label: _vm.$t("Tipo"),
                                  options: _vm.telephoneTypes(),
                                },
                                model: {
                                  value: tel.type,
                                  callback: function ($$v) {
                                    _vm.$set(tel, "type", $$v)
                                  },
                                  expression: "tel.type",
                                },
                              }),
                            ],
                            1
                          ),
                          _c(
                            "b-col",
                            {
                              key: "phone_number_" + tel.id,
                              attrs: { md: "4" },
                            },
                            [
                              _c("FormulateInput", {
                                attrs: {
                                  id: "register-phone-number-" + index,
                                  type: "label",
                                  filter: "phone",
                                  label: _vm.$t("Número"),
                                },
                                model: {
                                  value: tel.number,
                                  callback: function ($$v) {
                                    _vm.$set(tel, "number", $$v)
                                  },
                                  expression: "tel.number",
                                },
                              }),
                            ],
                            1
                          ),
                        ]
                      }),
                    ],
                    2
                  )
                : _c("FormulateInput", {
                    attrs: {
                      type: "group",
                      label: "Telefones",
                      repeatable: true,
                      "add-label": _vm.$t("Adicionar Telefone"),
                    },
                    scopedSlots: _vm._u([
                      {
                        key: "default",
                        fn: function (ref) {
                          var index = ref.index
                          return [
                            _c(
                              "b-row",
                              [
                                _c(
                                  "b-col",
                                  { attrs: { md: "4" } },
                                  [
                                    _c("FormulateInput", {
                                      class: { required: _vm.isCustomer },
                                      attrs: {
                                        id: "register-phone-type-" + index,
                                        name: "type",
                                        type: "vue-select",
                                        "aria-label": _vm.$t(
                                          "Tipo do número de contato"
                                        ),
                                        label: _vm.$t("Tipo"),
                                        options: _vm.telephoneTypes(),
                                        clearable: false,
                                        validation: _vm.isCustomer
                                          ? "required"
                                          : "",
                                        disabled: _vm.isCustomer && index === 0,
                                      },
                                    }),
                                  ],
                                  1
                                ),
                                _c(
                                  "b-col",
                                  { attrs: { md: "8" } },
                                  [
                                    _c("FormulateInput", {
                                      class: { required: _vm.isCustomer },
                                      attrs: {
                                        id: "register-phone-number-" + index,
                                        name: "number",
                                        type: "text-mask",
                                        maxlength: "15",
                                        instruction: _vm.$t(
                                          "Exemplo: (99) 99999-9999"
                                        ),
                                        mask:
                                          _vm.personForm.telephones[index] &&
                                          _vm.personForm.telephones[index]
                                            .type ===
                                            _vm.telephoneTypeEnum.MOBILE
                                            ? ["(##) #####-####"]
                                            : ["(##) ####-####"],
                                        label: _vm.$t("Número"),
                                        validation: _vm.isCustomer
                                          ? "required"
                                          : "",
                                      },
                                    }),
                                  ],
                                  1
                                ),
                              ],
                              1
                            ),
                          ]
                        },
                      },
                      {
                        key: "remove",
                        fn: function (ref) {
                          var removeItem = ref.removeItem
                          var index = ref.index
                          return [
                            index > 0
                              ? _c(
                                  "div",
                                  {
                                    staticStyle: {
                                      position: "absolute",
                                      right: "10px",
                                      top: "40px",
                                    },
                                  },
                                  [
                                    _c(
                                      "b-button",
                                      {
                                        staticStyle: {
                                          padding: "2px",
                                          width: "25px",
                                        },
                                        on: { click: removeItem },
                                      },
                                      [
                                        _c(
                                          "div",
                                          [
                                            _c("b-icon", {
                                              attrs: { icon: "x" },
                                            }),
                                          ],
                                          1
                                        ),
                                      ]
                                    ),
                                  ],
                                  1
                                )
                              : _vm._e(),
                          ]
                        },
                      },
                    ]),
                    model: {
                      value: _vm.personForm.telephones,
                      callback: function ($$v) {
                        _vm.$set(_vm.personForm, "telephones", $$v)
                      },
                      expression: "personForm.telephones",
                    },
                  }),
            ],
            1
          ),
          _c("b-col", [_vm._t("others-inputs")], 2),
        ],
        1
      ),
    ],
    1
  )
}
var staticRenderFns = []
render._withStripped = true

export { render, staticRenderFns }